import theme from '@solid-ui-theme'
import sizes from '@solid-ui-theme/sizes'
import colors from "./colors";
import gradient from "./buttons/gradient";

export default {
  ...colors,
  ...theme,
  ...gradient,
  sizes: {
    ...sizes,
    icon: {
        xs: 24,
        sm: 32,
        md: 48,
        lg: 64,
        xl: 128,
        xxl: 164
    }
  }
}
