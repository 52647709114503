// Importing default theme color object to mutate it
import defaultColors from '@elegantstack/solid-ui-theme/src/colors'

// Hispasec custom colors https://elegantstack-docs.netlify.app/flexiblocks/customization/template-colors/

const customColors = {
    // beta (primary)
    alphaLighter: `#182c49`,
    alphaLight: `#152741`,
    alpha: `#15253f`,
    alphaDark: `#111b31`,
    alphaDarker: `#11152a`,
    // beta (secondary)
    betaLighter: `#FF5425`,
    betaLight: `#f9461e`,
    beta: `#e7411d`,
    betaDark: `#d93e1c`,
    betaDarker: `#cf3b1c`,
    omegaDark: `#000000`,
    // Header
    // headerActiveColor: `#15253f`,
    // headerPinkColor: `#f75e97`,
}

const appColors = Object.assign(defaultColors, customColors)

export default appColors
